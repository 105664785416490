export const understandPayAsYouGoPricing = {
  order: 0,
  name: "Understand pay-as-you-go pricing",
  keywords: "datapoint unlock",
  subcategory: "Unlock & access datapoints",
  markdown: `# Understand pay-as-you-go pricing

  In Tower Hunt, you pay for the datapoints that you access rather than via a fixed fee per user. This provides the best alignment between the product, its users, and overall market conditions.

  ## What do you pay for

  In Tower Hunt, you explore specific areas of the map and can choose to {% inlineRouterLink articleId="unlock-datapoints" %}unlock{% /inlineRouterLink %} specific datapoints that you need. Datapoints can be {% inlineRouterLink articleId="unlock-datapoints" %}unlocked{% /inlineRouterLink %} in batches (ex. an entire property survey, an individual property, an individual deal) or individually (ex. a single price, square footage, or involved company). This means you can try Tower Hunt for almost nothing and then increase your investment based on how good of a job Tower Hunt does for you.

  ### Compare to other databases

  Most databases productize their data geographically, and some also package data by property use. You pay fixed prices for the packages that you want regardless of how much you use and how good the data is. This results in high fixed costs.

  ## When do you pay

  In Tower Hunt, you pay for the datapoints that you access rather than via a fixed fee per user. Your usage is subsidized by any usage credits that you earn from your contributions and validations. Your usage is billed on a monthly basis.

  ### Cost buffering

  When you {% inlineRouterLink articleId="unlock-datapoints" %}unlock{% /inlineRouterLink %} a datapoint, you are granted a license to access that datapoint in **5-minute increments** &mdash; we call this *cost buffering*. Each time you initiate a new 5-minute usage increment, you are charged the datapoint's then-current price. The datapoint can be freely reaccessed an unlimited number of times within the 5-minute increment.

  {% callout type="tip" %}
  **Usage example:** You {% inlineRouterLink articleId="unlock-datapoints" %}unlock{% /inlineRouterLink %} a datapoint containing the square footage of a floor area at a property. The price of the datapoint is $0.0045. Through your interactions with the property diagram, you access the datapoint 100 times over a one-hour period. Assuming constant usage throughout the hour, you are charged 12 times: 60 minutes / 5-minute increments = 12 usage increments. Your total cost for the hour is $0.054: $0.0045 x 12.
  {% /callout %}

  ## How do you pay

  When your usage exceeds your credit balance, you pay via credit card. Tower Hunt partners with Stripe for payment processing. You can connect multiple payment methods.

  ### Teams

  Teams can choose to pay for the usage of their members. {% inlineRouterLink articleId="types-of-roles-in-tower-hunt" %}Primary owners{% /inlineRouterLink %} and {% inlineRouterLink articleId="types-of-roles-in-tower-hunt" %}billing members{% /inlineRouterLink %} can connect payment methods to the team.

  ## How much does it cost

  Base pricing is **$0.005** for **paid** datapoints and **$0.001** for **free** datapoints. Tower Hunt datapoints are graded according to four criteria: recency, difficulty, sourcing, and credibility. A datapoint's grade produces a multiplier that is applied to the base price to determine the price you pay.

  ### Pricing summary

  When you are viewing an individual datapoint, the action menu offers a grading breakdown to support the pricing multiplier:

  ![Datapoint pricing summary screenshot](https://assets.towerhunt.com/site/individualDatapointUnlock0.png)

  ### Recency

  This grade looks at how recently a datapoint was created. For certain date-based datapoints (ex. investment date), it looks at the underlying date.

  | **Live** | **< 1 month** | **1-6 months** | **> 6 months** |
  |---|---|---|---|
  | 2.5x | 2.0x | 1.5x | 1.0x |

  #### Why it matters

  Recent data indicate current market conditions.

  ### Difficulty

  This grade considers how difficult it would be for an arm's length market participant to obtain the datapoint's information.

  | **High** | **Medium** | **Low** | **None** |
  |---|---|---|---|
  | 4.0x | 3.0x | 2.0x | 1.0x |

  {% callout type="tip" %}
  **Tip:** Looking for more detail about difficulty? Head over to our {% inlineRouterLink articleId="paid-data-types" %}list of paid data types{% /inlineRouterLink %} for a breakdown.
  {% /callout %}

  #### Why it matters

  Data requiring deep industry knowledge carries greater weight.

  ### Sourcing

  This grade looks at the number of **approved citations** attached to the datapoint.

  | **> 3 citations** | **1-3 citations** | **1 citation** | **No citations** |
  |---|---|---|---|
  | 2.5x | 2.0x | 1.5x | 1.0x |

  #### Why it matters

  Data backed by multiple sources carries greater weight.

  ### Credibility

  This grade looks at the **average reputation** of the datapoint's originator and validators.

  | **Gold** | **Silver** | **Bronze** | **Establishing reputation** |
  |---|---|---|---|
  | 4.0x | 3.0x | 2.0x | 1.0x |

  #### Why it matters

  Data contributed by reputable members carries greater weight.

  ### User and contributors benefits

  Dynamic pricing benefits users because it links the cost of data to its real-world value, taking into account the types of imperfections that are inherent in commercial real estate data. It also benefits contributors because the datapoints that matter most also cost the most.

  ### Task pricing

  Accessing a {% inlineRouterLink articleId="stay-organized-with-tasks" %}task{% /inlineRouterLink %} costs **$0.005** with a {% inlineRouterLink articleId="understand-pay-as-you-go-pricing" sectionName="When do you pay" %}5-minute buffer{% /inlineRouterLink %}.

  ## What happens when you pay

  Whenever you access {% inlineRouterLink articleId="what-is-a-datapoint?" %}datapoints{% /inlineRouterLink %}, Tower Hunt keeps track of your usage. You can see this reflected in real-time in your available balance within the {% inlineRouterLink articleId="key-actions-hub" %}Key Actions hub{% /inlineRouterLink %}.

  {% callout type="check" %}
  If you belong to a team that {% inlineRouterLink articleId="pay-for-team-member-usage" %}pays for its members' usage{% /inlineRouterLink %}, your usage will be billed to the team.
  {% /callout %}

  ### Public data

  All public datapoints {% inlineRouterLink articleId="how-to-earn-on-tower-hunt" %}share revenue or data credits{% /inlineRouterLink %} with their {% inlineRouterLink articleId="understand-how-datapoints-become-public" %}contributors{% /inlineRouterLink %} and {% inlineRouterLink articleId="understand-validation" %}validators{% /inlineRouterLink %}.

  ### Safezone data

  Safezone datapoints do not participate in {% inlineRouterLink articleId="how-to-earn-on-tower-hunt" %}revenue sharing{% /inlineRouterLink %}. However, {% inlineRouterLink articleId="share-datapoints" %}shared datapoints{% /inlineRouterLink %} can be {% inlineRouterLink articleId="gift-shared-datapoints" %}gifted on a recipient-by-recipient basis{% /inlineRouterLink %}. So, sometimes your usage of Safezone datapoints may be billed to a sharer, or a recipient's usage may be billed to you.`,
};
